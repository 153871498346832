import "./App.css";
import React, { useEffect } from "react";
import Survey from "./Survey.js";

function App() {
  useEffect(() => {
    async function fetchData() {
      let data = await fetch("/api/email");
    }
    fetchData();
  }, []);

  return (
    <div className="App background-img lime-text text-darken-4">
      <div className="row container">
        <h3>BetterSteader</h3>
      </div>
      <div className="row container">
        <div className="col s6 left-align">
          <h5>Welcome to BetterSteader!</h5>
          <p>
            The team here at BetterSteader is excited about the possiblities
            that are in store for homesteading and farmsteading families. We
            want to be a part of it! Our desire is to develop Web and Mobile
            Apps that can truly be a blessing to those who desire to live off
            the fruit of their own land.
          </p>
          <h5>How can you help?</h5>
          <p>
            Homesteading is a very broad subject that can go in many different
            directions. We need your help to identify what would be most helpful
            and meaningful for you to keep track of your homesteading data. We
            would love to hear from you! We have included a short survey that
            would be helpful for us to determine the largest needs of
            homesteaders and farmsteaders. If you prefer, you can drop us an
            email at{" "}
            <a href="mailto:feedback@bettersteader.com">
              feedback@bettersteader.com
            </a>
            . By giving us your opinion you will not only help drive our product
            direction, but you will also be helping your fellow homesteaders of
            the future! We look forward to hearing from you!
          </p>
        </div>
        <div className="col s6 left-align">
          <div className="col s3"></div>
          <div className="col s6">
            <Survey />
          </div>
          <div className="col s3"></div>
        </div>
      </div>
    </div>
  );
}

export default App;
