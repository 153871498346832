import "./App.css";
import React, { useEffect, useState } from "react";

function Survey() {
  const [surveyItems, setSurveyItems] = useState([]);
  const [surveySubmitted, setSurveySubmitted] = useState(false);

  function checkboxChange(e) {
    if (e.target.checked) {
      setSurveyItems([...surveyItems, e.target.value]);
    } else {
      setSurveyItems(surveyItems.filter((i) => i !== e.target.value));
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    let data = await fetch(event.target.action, {
      method: "POST",
      body: JSON.stringify({
        email: event.target.email.value,
        interests: surveyItems.map((i) => i),
        additional_comments: event.target.comments.value,
      }),
    });

    setSurveySubmitted(true);
  }

  return (
    <>
      {surveySubmitted ? (
        <div>
          <h4>Thank You!</h4>
        </div>
      ) : (
        <form
          id="survey"
          method="POST"
          action="api/email"
          onSubmit={(e, f) => handleSubmit(e, f)}
        >
          {[
            "Chicken Records",
            "Cattle Records",
            "Horse Records",
            "Goat Records",
            "Rabbit Records",
            "Bee Keeping Records",
            "Equipment Records",
            "Garden Planning",
            "Food Storage Records",
            "Sale/Transaction Records",
            "Task List",
          ].map((i) => {
            return (
              <div key={i}>
                <label>
                  <input
                    id={i}
                    type="checkbox"
                    className="filled-in"
                    value={i}
                    onChange={checkboxChange}
                  />
                  <span className=" lime-text text-darken-4">{i}</span>
                  {/* <label htmlFor={i}>{i}</label> */}
                </label>
              </div>
            );
          })}
          <div>
            <label>
              <textarea
                id="comments"
                placeholder={
                  "Is there anything else that you would like to share?"
                }
              />
            </label>
          </div>
          <div>
            <div className="input-field inline">
              <input id="email" type="email" className="validate" />
              <label htmlFor="email">Email</label>
              <span
                className="helper-text"
                data-error="Incorrect Email Format"
              ></span>
            </div>
          </div>
          <input className="btn wave-effect" type="submit" value="Submit" />
          {/* <button className="btn wave-effect" onClick={submitForm}>
          Submit Feedback
        </button> */}
        </form>
      )}
    </>
  );
}

export default Survey;
